import { makeStyles } from "@material-ui/styles"


const styles = ({ palette }) => ({
  photo_header: {
    color: palette.white.main,
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "30px !important",
    marginLeft: "10px",
    marginBottom: "20px",
  },
  img_container: {
    display: "flex",
    justifyContent: "center",
  },
  img_box: {
    width: "100%",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    position: "relative",
    transition: "transform .25s ease",
    "&:hover":{
      transform: "scale(1.05)"
    }
  }
})

const functions = {
  makeImage: (url) => makeStyles({
    root: {
      "&::before": {
        content: "''",
        width: "100%",
        height: "100%",
        zIndex: "-1",
        filter: "brightness(.8)",
        position: "absolute",
        backgroundSize: "cover",
        background: `url(${url}) center no-repeat`
      }
    }
  })().root
}

export {
  styles,
  functions
}
